(function(){
    angular
        .module('app')
        .directive('setClassWhenAtTop', setclassonscroll);

        setclassonscroll.$inject = ['$timeout','$window'];
        function setclassonscroll($timeout,$window){
        	var $win = angular.element($window); // wrap window object as jQuery object
            return {
            	link: function (scope, element, $attrs) {
		            return $timeout(function () {
		            	var topClass = $attrs.setClassWhenAtTop; // get CSS class from directive's attribute value
						let firstTop = element.offset().top;
						var scrollPos = 0;
			            //var existeModal = $('.modal-dialog').length;
			            $win.on('scroll', function (e) {
			            	//console.log("genlab",genLab)
								
							if ($win.scrollTop() >= firstTop && element[0].parentElement.getBoundingClientRect().bottom >= element[0].getBoundingClientRect().height) {
								element[0].style.width = (element[0].parentElement.clientWidth - 24) + 'px'
								element.removeClass('final-tarjeta');
								element.addClass(topClass);
							} else if(element[0].parentElement.getBoundingClientRect().bottom <= element[0].getBoundingClientRect().height) {
								element.addClass('final-tarjeta');
							} 
							if((document.body.getBoundingClientRect()).top > scrollPos) {
								element.addClass('top-header');
								element[0].style.width = ''
							} else  {
								element.removeClass('top-header');
							}
							scrollPos = (document.body.getBoundingClientRect()).top;

			            });

			        });
		        }
          }
      }
})();