(function () {
    angular
        .module('app')
        .directive('swipervideo', setswipervideo);

    setswipervideo.$inject = ['$timeout', '$window'];
    function setswipervideo($timeout, $window) {
        return {
            link: function (scope, element, $attrs) {
                return $timeout(function () {
                    let videos = element[0].querySelectorAll('.vlite-video');
                    let swiper;
                    let autoSlideTimeout;
                    let poster = null;
                    let videoInstance = [];
                    
                    function clearAutoSlideTimeout() {
                        if (autoSlideTimeout) {
                            clearTimeout(autoSlideTimeout);
                            autoSlideTimeout = null;
                        }
                    }
                    
                    videos.forEach(video => {
                        let options = {
                            controls: false,
                            volume: false,
                            fullscreen: true,
                            loop: false,
                            muted: true,
                            playPause: false,
                            progressBar: false,
                            time: false,
                            bigPlay: false,
                            autoplay: false,
                        };
                        let provider = null;

                        if (video.classList.contains('vimeo')) {
                            provider = "vimeo";
                        }
                        if (video.classList.contains('youtube')) {
                            provider = "youtube";
                        }
                        if(video.dataset.poster) {
                            poster = video.dataset.poster;
                        }
                        let vliteConfig = {
                            options: options,
                        };

                        if (provider) {
                            vliteConfig.provider = provider;
                        }
                        if (poster) {
                            vliteConfig.poster = poster;
                        }
                      

                        let vid = new $window.Vlitejs(video, vliteConfig)
                        vid.onReady = function (player) {
                            angular.forEach(videoInstance, function(vI, key) {
                            
                                if(vI.id == player.media.id)
                                {
                                    videoInstance[key].player = player;
                                }
                            });
                        }

                        videoInstance.push( { id:video.id ,video:vid,player:null });
                    });

                    swiper = new Swiper(element[0], {
                        loop: true,
                        speed: 500,
                        effect: 'creative',
                        simulateTouch: false,
                        creativeEffect: {
                            prev: {
                                shadow: true,
                                translate: [0, 0, -1],
                            },
                            next: {
                                translate: ["100%", 0, 0],
                            },
                        },
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        pagination: {
                            el: ".swiper-pagination",
                            type: "fraction",
                        },
                        on: {
                            init: function () {
                                console.log('hello')
                                handleAutoSlide(this.slides[this.activeIndex], videoInstance);
                            },
                            activeIndexChange: function () {
                                clearAutoSlideTimeout();
                                this.slides[this.previousIndex].querySelector('.card').classList.remove('show');
                                this.slides[this.activeIndex].querySelector('.card').classList.add('show');
                                if(this.activeIndex > 0) {
                                   if( this.slides[this.activeIndex - 1] ) {
                                        if(videoInstance[this.slides[this.activeIndex - 1].dataset.video]) { 
                                            let video = videoInstance[this.slides[this.activeIndex - 1].dataset.video];
                                            video.player.pause() 
                                        }
                                   }
                                }
                                handleAutoSlide(this.slides[this.activeIndex], videoInstance);
                            },
                        }
                    });
                    // function handleAutoSlide(slide, videoInstance)
                    function handleAutoSlide(activeSlide, videos) {
                        clearAutoSlideTimeout();
                        if(videos[activeSlide.dataset.video]) {
                            let video = videos[activeSlide.dataset.video];
                            $timeout(function(){
                                
                                if(video.player){
                                    video.player.off("ended");
                                    video.player.on("ended", () => {
                                        swiper.slideNext()
                                    });
                                    video.player.play() 
                                }
                                       
                            },1000)
                            
                            
                        } else {
                            autoSlideTimeout = setTimeout(function () {
                                swiper.slideNext();
                            }, 6000);
                        }
                    }
                   
                });

            }
        };
    }
})();
