(function(){
    angular
        .module('app')
        .directive('scrollHeader', setclassonscrollHeader);

        setclassonscrollHeader.$inject = ['$timeout','$window'];
        function setclassonscrollHeader($timeout,$window){
        	var $win = angular.element($window); 
            return {
            	link: function (scope, element, $attrs) {
                    var topClass = $attrs.scrollHeader;
                    var $win = angular.element($window);
                    var maxTranslateY = -43; 
                    var maxTranslateyMov= -100; 
                    var scrollPos = 0;
                    function resizeHeader() {
                        var maxScroll = element[0].offsetHeight; 

                        if(window.matchMedia('(min-width: 769px)').matches) {
                            if ($win.scrollTop() <= maxScroll) {
                                var translateY = ($win.scrollTop() / maxScroll) * maxTranslateY;
                                element.css('transform', 'translateY(' + translateY + '%)');
                            } else {
                                
                                element.css('transform', 'translateY(' + maxTranslateY + '%)');
                            }
                            if ($win.scrollTop() > maxScroll) {
                                element.addClass(topClass);
                            } else {
                                element.removeClass(topClass);
                                element.removeClass('animating');
                            }
                        } else {
                            if((document.body.getBoundingClientRect()).top > scrollPos) {
                                element.css('transform', 'translateY(0%)');
                            } else  {
                                element.css('transform', 'translateY(' + maxTranslateyMov + '%)');
                            }
                            scrollPos = (document.body.getBoundingClientRect()).top;
                        }
                    }
		            return $timeout(function () {
                        // document.getElementById('main').style.marginTop =  (element[0].offsetHeight + 16) + 'px';
                        // $win.on('resize', function (e) { 
                        //     resizeHeader()
                        //     document.getElementById('main').style.marginTop =  (element[0].offsetHeight + 16) + 'px';
                        // })

                        $win.on('scroll', function() {
                            resizeHeader()
                        })
			        });
		        }
          }
      }
})();