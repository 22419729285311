(function () {
    angular
        .module('app')
        .directive('swiper', setswiper);

    setswiper.$inject = ['$timeout', '$window'];
    function setswiper($timeout, $window) {
        return {
            link: function (scope, element, $attrs) {
                let swiper = null;
                let observer = null;
                return $timeout(function () {
                    let swiperConfig = {};
                    // let swiperConfig = {
                    //     preventClicks: false,
                    //     preventClicksPropagation: false
                    // };
                    let swiper;
                    function initializeSwiper() {
                     
                        if ($attrs.swiper.includes("pagination")) {
                            swiperConfig.pagination = {
                                el: ".swiper-pagination",
                                type: "fraction",
                            };
                        }

                        if ($attrs.swiper.includes("navigation")) {
                            swiperConfig.navigation = {
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            };
                        }

                        if ($attrs.swiper.includes("grabCursor")) {
                            swiperConfig.grabCursor = true;
                        }

                        if ($attrs.swiper.includes("slidesPerView")) {
                            swiperConfig.slidesPerView = "auto";
                        }

                        if ($attrs.swiper.includes("spaceBetween")) {
                            swiperConfig.spaceBetween = 24;
                        }
                        if ($attrs.swiper.includes("tablet")) {
                            if($attrs.swiper.includes('980')) {
                                if ($window.matchMedia("(max-width: 980px)").matches) {
                                    swiper = new Swiper(element[0], swiperConfig);
                                } else {
                                    swiper = null;
                                }
                            } else {
                                if ($window.matchMedia("(max-width: 979.98px)").matches) {
                                    swiper = new Swiper(element[0], swiperConfig);
                                } else {
                                    swiper = null;
                                }
                            }
                        } else if($attrs.swiper.includes("movil")){
                            if ($window.matchMedia("(max-width: 768px)").matches) {
                                swiper = new Swiper(element[0], swiperConfig);
                            } else {
                                swiper = null;
                            }
                        } else {
                            swiper = new Swiper(element[0], swiperConfig);

                        }
                    }
                    if(element[0].classList.contains('swiper-uranolab')) {
                        const links = element[0].querySelectorAll('.swiper-slide a');
                        const observer = new IntersectionObserver((entries) => {
                            entries.forEach(entry => {
                                const link = Array.from(links).find(link => link.getAttribute('href') === `#${entry.target.id}`);
                                if (entry.isIntersecting && link) {
                                    links.forEach(l => l.parentElement.classList.remove('active'));
                                    link.parentElement.classList.add('active');
                                    if (swiper !== null) {
                                        swiper.slideTo(Array.from(links).indexOf(link));
                                    }
                                }
                            });
                        }, {
                            root: null, // Usar el viewport como root
                            rootMargin: '0px',
                            threshold: 0.8 // El 50% del elemento debe estar visible para que se dispare la acción
                        });
                    
                        links.forEach((link, index) => {
                            link.addEventListener('click', (event) => {
                                event.preventDefault();
                                const targetId = link.getAttribute('href').substring(1);
                                const targetElement = document.getElementById(targetId);
                    
                                if (targetElement) {
                                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                    if (swiper !== null && index !== -1) {
                                        swiper.slideTo(index);
                                    }
                                }
                            });
                    
                            const targetId = link.getAttribute('href').substring(1);
                            const targetElement = document.getElementById(targetId);
                            if (targetElement) {
                                observer.observe(targetElement);
                            }
                        });
                    }

                    function destroySwiper() {
                        if (swiper) {
                            swiper.destroy(true, true);
                            swiper = null;
                        }
                    }

                    function checkAndInitializeSwiper() {
                        destroySwiper();
                        initializeSwiper();
                    }

                    angular.element($window).on('resize', checkAndInitializeSwiper);

                    $timeout(checkAndInitializeSwiper);

                    scope.$on('$destroy', function () {
                        destroySwiper();
                        angular.element($window).off('resize', checkAndInitializeSwiper);
                    });
                });
            }
        }
        
    }
})();




